<template>
    <section>
        <!--工具条-->
        <el-col :span="8" class="toolbar roles">
            <el-card class="box-card" style="max-height:650px;overflow-y:auto;">
                <div slot="header" class="clearfix">
                    <el-form :inline="true">
                        <el-form-item label="组织机构：" width>
                            <el-cascader
                                placeholder="请选择，支持搜索功能"
                                style="width: 250px"
                                v-model="OptionTree"
                                :options="options"
                                :props="defaultProps"
                                filterable
                                change-on-select
                            ></el-cascader>
                        </el-form-item>
                        <el-button @click="getRoles" style="float: right; padding: 3px 0" type="text">查询</el-button>
                    </el-form> 
                </div>
                <div v-for="o in roles" :key="o.Id" @click="operate(o.Id)" :class="o.Id==roleid ? 'active':''"
                     class="text item role-item" v-loading="listLoading1">
                    {{o.Name }}
                </div>
                <el-pagination
                    layout="prev, pager, next"
                    :total="pages.pageTotal"
                    :page-size="pages.pageSize"
                    @prev-click="prevPage"
                    @next-click="nextPage"
                    @current-change="handleCurrentChange"
                    style="margin-top:15px;">
                </el-pagination>
            </el-card>
        </el-col>
        <el-col :span="16" class="toolbar perms morechildren">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>数据</span>
                    <!-- <el-button @click="upLoadFile" style="float: right; padding: 3px 0" type="text">文件上传</el-button> -->
                    <!-- <el-button :loading="loadingSave" @click="setDataPermissions" style="float: right; padding: 3px 0" type="text">{{loadingSaveStr}}</el-button> -->
                    <tool-bar style="float: right;width: 135px;" class="editSom" :buttonList="buttonList" @callFunction="callFunction"></tool-bar>
                </div>
                <div class="block">
                    <el-table
                    :data="dataTree"
                    :show-header="false"
                    v-loading="listLoading"
                    row-key="Id"
                    ref="multipleTable"
                    max-height="650px"
                    :row-style="{height:'30px'}"
                    style="width: 100%">
                        <el-table-column
                            prop="Name"
                            label="姓名"
                            width>
                            <template slot-scope="scope">
                                <span>{{ scope.row.Name }}</span>
                                <span class="custom-tree-node" style="display:block;float:right;">
                                    <span>
                                        <el-radio-group v-model="scope.row.TypeCode">
                                            <el-radio :label="'all'" v-if="!scope.row.IsHideAll">全部</el-radio>
                                            <el-radio :label="'olnydept'" v-if="!scope.row.IsHideOlnydept">本部</el-radio>
                                            <el-radio :label="'belowdept'" v-if="!scope.row.IsHideBelowdept">本部及以下</el-radio>
                                            <el-radio :label="'self'" v-if="!scope.row.IsHideSelf">本人</el-radio>
                                            <el-radio :label="'none'">无</el-radio>
                                        </el-radio-group>
                                    </span>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </el-col>

        <!-- 文件上传的dialog============================================================================ -->
        <el-dialog
        :title="'文件上传'"
        :visible.sync="addFormVisiblec"
        v-model="addFormVisiblec"
        :close-on-click-modal="true"
        >
            <el-upload
            class="upload-demo"
            drag
            :action="uploadImagesUrl"
            :file-list="fileList"
            :limit="5"
            :on-exceed="handleExceed"
            :before-remove="handleBeforeRemove"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-preview="handlepreview"
            multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisiblec = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- ========================================================================================================= -->
    </section>
</template>

<script>
import toolBar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {getRoleListPage , getAssignDataPermissionIdByRoleId , assignDataPermissions , getOrganizationTree } from '../../api/api';
export default {
    components: { toolBar },
    data() {
        return {
            uploadImagesUrl: "http://192.168.1.4:5000/Test/UploadPicture",
            radios: [],
            buttonList: [],
            roles: [],
            roleid:"00",
            listLoading: false,
            listLoading1: false,
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageIndex: 1, //默认进入页
                pageTotal: 20, //默认总条数
            },
            dataTree:[],
            loadingSaveStr:'保存',
            loadingSave:false,
            defaultProps: { 
                children: "Children",
                label: "Name",
                value:'Id'
            },
            OptionTree: [],  //组织机构数据
            options: [],
            
            // 以下文文件上传的数据=========================================================================
            addFormVisiblec: false,
            fileList: [
                {
                    name: 'food.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                },
                {
                    name: 'food2.jpeg',
                    url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                }
            ]
            // 文件上传的数据结束===========================================================================
        }
    },
    methods: {
        // 测试文件上传函数==================================================================================
        upLoadFile() {
            this.addFormVisiblec = true;
        },
        // 文件上传数超过设定数量的函数
        handleExceed() {
            console.log("上传文件已超过三个文件")
        },
        // 移除文件之前的函数
        handleBeforeRemove() {
            return this.$confirm('确认删除该数据吗?', '提示', {
                type: 'warning'
            }).then(() => {
                
            })
        },
        // 移除文件列表中文件的函数
        handleRemove(file) {
            console.log("移除了文件",file)
        },
        // 文件上传成功的函数
        handleSuccess() {
            console.log("上传文件成功")
        },
        // 点击文件列表中文件的函数
        handlepreview() {
            console.log("点击了文件")
        },
        // 测试文件上传函数结束===============================================================================

        // 点击了查询，新增，编辑，删除
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getRoles() {
            this.getRoleListPage();
        },
        operate(id) {
            if(id !=="00"){
                this.loadingSave=true;
                this.loadingSaveStr='加载中...';
                this.roleid = id;
                var params = {
                    roleId: this.roleid
                }
                this.getDataTree(params);
            }
        },
        //获取角色信息分页列表
        getRoleListPage() {
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.OptionTree.length > 0){
                params.organizationInfoId = this.OptionTree[this.OptionTree.length-1];
            }
            this.listLoading1 = true
            getRoleListPage(params).then(res => {
                var data = res.data
                if(data.Success){
                    this.listLoading1 = false
                    if(data.Response.Data.length > 0){
                        this.roles = data.Response.Data
                        this.pages.pageSize = data.Response.PageSize
                        this.pages.pageTotal = data.Response.DataCount
                    }else{
                        this.roles = [{
                            Id:"00",
                            Name:"暂无数据..."
                        }]
                    }
                }else{
                    this.$message({
                        message: data.Message,
                        type: "error"
                    });
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        prevPage(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        nextPage(val) {
            this.pages.pageIndex = val;
            this.getRoleListPage();
        },
        // 获取菜单
        getDataTree(params) {
            this.listLoading = true
            getAssignDataPermissionIdByRoleId(params).then(res => {
                var data = res.data
                if(data.Success){
                    this.listLoading = false
                    this.dataTree = data.Response
                    this.loadingSaveStr='保存';
                    this.loadingSave=false;
                }else{
                    this.$message({
                        message: data.Message,
                        type: "error"
                    });
                }
            })
        },
        // 获取树结构数据
        getOrganizations() {
            var _this = this
            var ID = JSON.parse(localStorage.getItem("user")).Id
            var params = {
                parentId: 0
            }
            _this.options = []
            getOrganizationTree(params).then(res => {
                var data = res.data
                var resData = [data.Response]
                if(data.Success){
                    if(data.Response.Name == "根节点"){
                        _this.options = data.Response.Children
                    }else{
                        _this.options = resData
                    }
                }
            })
        },
        // 保存
        setDataPermissions() {
            let _this=this;
            this.loadingSave=true;
            this.loadingSaveStr='保存中...';
            var params = {}
            if(this.roleid == "00"){
                this.$message({
                    message: "请选择角色进行数据权限分配！",
                    type: "warning"
                });
                this.loadingSave=false;
                this.loadingSaveStr='保存';
                return false;
            }else{
                params.RoleInfoId = this.roleid
            }
            var details = []
            this.dataTree.map((res) => {
                var dataObj = {}
                if(res.TypeCode){
                    dataObj.DataPermissionsInfoId = res.DataPermissionsInfoId
                    dataObj.TypeCode = res.TypeCode
                }
                details.push(dataObj)
            })
            params.Details = details
            // return false;
            assignDataPermissions(params).then(res => {
                var data = res.data
                if(data.Success){
                    this.$message({
                        message: data.Message,
                        type: "success"
                    });
                    this.loadingSave=false;
                    this.loadingSaveStr='保存';
                    var paramsc = {
                        roleId: this.roleid
                    }
                    this.getDataTree(paramsc);
                }else{
                    this.$message({
                        message: data.Message,
                        type: "error"
                    });
                    setTimeout(function(){
                        _this.loadingSave=false;
                        _this.loadingSaveStr='保存';
                    },3000)
                }
            })
            .catch(err => {
                this.$message({
                    message: "保存出错了！",
                    type: "error"
                });
                setTimeout(function(){
                    _this.loadingSave=false;
                    _this.loadingSaveStr='保存';
                },3000)
            })
        },
    },
    created() {
        this.getRoleListPage();
        this.getDataTree();
        this.getOrganizations();
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .role-item {
        cursor: pointer;
        padding: 10px;
    }

    .role-item.active {
        background: #ebf5ff;
    }

    .role-item:hover {
        background: #ebf5ff;
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

    .text {
        font-size: 14px;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .box-card {
        width: 90%;
    }


    .morechildren .el-checkbox{
        margin-right: 5px !important;
        float: left;
    }
    .morechildren .el-checkbox-group{
        margin-left: 50px;
        padding: 5px;
    }
    .morechildren .el-tree-node__content{
        height: auto !important;
    }
</style>